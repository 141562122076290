.c-primary {
    color: $troi-main;
}

.f-small {
    font-size: $troi-desk-small-size;
}

.tnd-subheadline {
    font-size: $troi-desk-sub-healine-size;
    color: $troi-main;
}

.c-pointer {
    cursor: pointer;
}

.tnd-error-msg {
    color: $troi-desk-color-error;
}

.tnd-flex-1 {
    flex: 1;
}

widget-container {
    font-size: $troi-desk-default-size;

    h3, h4 {
        font-size: $troi-desk-healine-size;

        troi-icon {
            margin-right: 1rem;
        }
    }

    troi-loading-spinner {
        z-index: 0 !important;
    }

    .section {
        margin: 0 -1.5rem;
        padding: 1rem 1.5rem;
        box-shadow: 0 -10px 14px -9px #c2c2c2;
        min-height: 200px;

        h4 {
            color: $troi-main;
            margin-bottom: 1rem;
        }

        .devider {
            height: 0;
            border-bottom: 1px solid $troi-color-silver;
            margin: 0 -1.5rem;
        }
    }

    .tnd-devider {
        height: 0;
        border-bottom: 1px solid $troi-color-silver;
        margin: 0 -1.5rem;
    }

    .tnd-devider-partial {
        height: 0;
        border-bottom: 1px solid $troi-color-silver;
    }
}

.hover {
    troi-icon:hover {
        span::before {
            font-weight: bold;
            cursor: pointer;
        }
    }
}

.btn {
    padding: 5px 35px;
    border: 1px solid $troi-main;
    background-color: $troi-main;
    color: $troi-white;
    border-radius: 2px;

    &.btn-secondary {
        background-color: $troi-white;
        color: $troi-main;
    }

    &:hover {
        border-color: $troi-main;
        box-shadow: $troi-desk-widget-shadow;
    }
}

.tnd-scrollbar {
    scrollbar-color: $troi-color-almost-white-lighter $troi-white;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: .5rem;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $troi-desk-color-dark-grey;
    }
}

/* General Caledar Styles */

.btn-group {
    .calendar-nav-btn {
        margin: 0 .5rem;
        font-size: $troi-desk-healine-size;
    }
}

.cal-month-view {
    margin-bottom: 50px;

    .cal-header {
        margin-bottom: 35px;
    }

    .cal-cell-top {
        min-height: 35px;
    }
    .cal-days {
        border: none;

        .cal-cell-row {
            border: none;

            &:hover,
            &.active-row {
                background-color: $troi-desk-color-lighter-blue;
                cursor: pointer;
            }

            .cal-cell {
                border: none;
                width: 35px;
                height: 35px;
                min-height: 35px;
                text-align: center;
                line-height: 35px;
                background-color: transparent;
            }
        }
    }

    .cal-day-number {
        margin: 0;
        float: none;
        line-height: 35px;
        display: inline-block;
        width: 35px;
        height: 35px;
        border-radius: 20px;
    }

    .cal-day-cell.cal-today {
        background-color: transparent;
        .cal-day-number {
            background-color: $troi-desk-color-light-blue;
            font-size: inherit;
        }
    }
}

.hidden {
    display: none;
    opacity: 0;
}

widget-container {
    troi-loading-spinner {
        .listing-overlay {
            position: relative !important;
        }
    }
}

.md-drppicker.ltr.double {
    width: 497px !important;
}


/** Tabs */

.tnd-nav-tabs {
    border: none;
    display: flex;

    .nav-item {
        color: $troi-desk-color-light-grey;
        flex: 1;
        text-align: center;
        border-bottom: 2px solid $troi-desk-color-light-grey;

        &.active {
            color: $troi-main;
            border-color: $troi-main;
        }
    }
}

/** Forms */

.btn {
    font-size: $troi-desk-sub-healine-size
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group.radio-group {
    line-height: 20px;
}

.tnd-text-icon-input {
    position: relative;;
}

input:focus {
    outline: none;
    border-color: $troi-main;
}

.tnd-text-input {
    width: 100%;
    border: none;
    min-height: 40px;
    border-bottom: 1px solid $troi-desk-color-light-grey;
    margin-bottom: 10px;
    font-size: $troi-desk-small-size;
    padding: 10px 0;

    &--transparent-background {
      background-color: transparent;
    }
    &--has-icon {
        padding-left: 40px;
    }

    &--error {
      border-color: $troi-desk-color-error;
    }

    &:focus {
        border-color: $troi-main;
    }
}

.tnd-text-input__icon-type {
    position: absolute;
    left: 9px;
    top: 10px;
}

.tnd-text-input__icon-align-right {
    left: initial;
    right: 9px;
}

tnd-search-select > .tnd-search-select {
    .tnd-search-select__result-list {
        overflow: auto;
    }
}

body {
    .modal-backdrop {
        background-color: $troi-white;
    }

    .modal-dialog.modal-iframe {
        max-width: 1400px;

        .modal-content {
            border: 0;
            box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
            overflow: visible !important;

            .tnd-close {
                position: absolute;
                top: -.75rem;
                left: -.75rem;
                padding: 0.5rem;
                opacity: 1;
                border-radius: 1.1rem;
                font-size: 2rem;
                line-height: 1rem;
                cursor: pointer;
                z-index: 1060;
                background-image: url('/site/resources5/images/lightbox_close.png');
                width: 26px;
                height: 26px;
            }

            .modal-body-iframe {
                border: 0;
            }
        }
    }
}

.tooltip-light .tooltip-inner {
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 15px 4px lightgrey;
}
.tooltip-light .arrow::before {
    border-top-color: #fff;
}

