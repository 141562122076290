@import "constants";

.troi-popover {
    box-shadow: 0 0 10px 0 $troi-color-silver;
    border-radius: 2px;
    border: none;
    font-size: $troi-desk-default-size;
  max-width: 400px !important;
  z-index: 999999;
  &__title {
    font-size: 20px;
    line-height: 24px;
    white-space: nowrap;
  }
  &__row {
    display: flex;
    justify-content: space-between;
  }
    &.widget-selector {
        //width: 550px;
        max-width: none;
      .popover-body {
        max-height: calc(100vh - 100px);
        overflow-y: auto;
      }
    }

    &.add-dates {
        width:350px;
        max-width: none;
    }

    &.add-absences {
        width:350px;
        max-width: none;

        &.align-top {
            top: 150px !important;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.edit-thresholds {
        width:520px;
        max-width: none;
    }

    &.record-project-time {
       position: fixed !important;
    }

    &.record-project-time, &.search-calc-position, &.search-project {
        width: 350px;
        max-width: none;
    }

    &.search-calc-position {
        height: 655px;

        &.align-top {
            margin-top: 300px;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.search-project {
        height: 600px;

        &.align-top {
            margin-top: 268px;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.create-stopwatch {
        width: 350px;
        max-width: none;

        &.align-top {
            top: 150px !important;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.search-contact, &.create-contact {
        width: 350px;
        max-width: none;
    }

    &.create-contact {
        &.align-top {
            top: 205px !important;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.search-contact {
        &.align-top {
            top: 50px !important;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.create-todo {
        width: 400px;
        max-width: none;

        &.align-top {
            top: 227px !important;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.edit-thresholds {
        &.align-top {
            top: 60px !important;

            .arrow {
                top: 31px !important;
            }
        }
    }

    &.edit-worktime {
        width: 450px;
        max-width: none;
    }

    .popover-header {
        font-family: 'Troi-Regular', Fallback, sans-serif;
        padding: 1.5rem 1.5rem .5rem 1.5rem;
        background: $troi-white;
        border-bottom: none;
    }
    .popover-body {
        padding: 0 1.5rem 1rem;
    }

    &.edit-thresholds .popover-body {
        padding-bottom: 4rem;
    }

    .popover-footer {
        .btn {
            flex: 1;
        }
    }

    .widget-btn {
        display: inline-block;
        user-select: none;
        width: 235px;
        padding: 1rem;
        border: 1px solid $troi-color-silver;
        color: $troi-color-silver;
        margin: .5rem;
        cursor: pointer;

        &.active {
            border: 1px solid $troi-main;
            box-shadow: inset 0 0 0 1px $troi-main;
            color: $troi-color-black;
        }

        .title {
            margin-left: 1.5rem;
        }
    }
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 2.5rem;
}

.bs-popover-bottom > .arrow::before {
    top: -7px;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after {
    top: -7px;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: #fff;
}


.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 1.8rem;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: -8px;
    top: -8px;
    border-width: 1rem 1rem 1rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);

}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    left: -7px;
    top: -8px;
    border-width: 1rem 1rem 1rem 0;
    border-right-color: #fff;
}


.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 1.8rem;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
    right: -7px;
    top: 0px;
    border-width: 1rem 0 1rem 1rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
    right: -6px;
    top: 0px;
    border-width: 1rem 0 1rem 1rem;
    border-left-color: #fff;
}

search-calc-position, record-project-time, search-project {
    .icon-plus {
        color: $troi-main;
        cursor: pointer;
    }
}
